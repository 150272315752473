/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import AOS from 'aos/dist/aos.js';

import 'responsive-bp/src/js/responsive.core.js';
import 'responsive-bp/src/js/responsive.modal.js';
import 'lightgallery/src/js/lightgallery.js';
import 'lg-video/src/lg-video.js';

$(document).ready(async function () {

	// Init Animate On Scroll
	AOS.init({
		once: true,
		easing: 'ease-in-sine'
	});

	// Responsive menu
	$('.toggler').each(function () {
		$(this).click(toggleMenu);
	});

	function toggleMenu() {
		var toggler = $('.toggler')
		var responsiveNav = $('.responsive-nav');

		responsiveNav.toggleClass('-open');
		toggler.toggleClass('is-active');
	}

	// Open language on touch
	$('.languages').each(async function () {
		var $this = $(this);

		$this.on('touchend', function (e) {
			$this.addClass('open');
			e.stopPropagation();
		});

		$(document).on('touchend', function () {
			$this.removeClass('open');
		});
	});

	// Modal
	$(".overview.-product .product .button").each(function () {
		var $this = $(this);
		var $parent = $('.overview.-product');
		var $modalClose = null;

		$this.modal({
			target: '/umbraco/Surface/Modal/Product?overviewId=' + $parent.data("overview-id") + '&id=' + $this.data("id"), // Use AJAX with element filter
			modal: true,
			fitViewport: false
		}).on({
			'shown.r.modal': function () {
				var $overlay = $('.modal-overlay');
				var options = $this.data("r.modal").options;

				$modalClose = $('<button class="round icon-only modal-close"><span class="visuallyhidden">' + options.closeHint + '</span></button>').appendTo($overlay.find('.overview-modal'));
				$modalClose.one('click', function () {
					$this.modal('hide');
				});
			}
		});
	});

	// Lightbox
	$('body:has([rel=lightbox])').each(async function () {
		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});
});